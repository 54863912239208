body {
    background-color: #e4e4e4;
}

.question-wrapper {
    display: flex;
    flex-direction: row;
    gap: 30px;
}

.question-wrapper .left-content {
    width: 100%;
    font-size: 16px;
    margin-bottom: 300px;
}

.question-wrapper .left-content,
.question-wrapper .right-content {
    background: #FFF;
    padding: 20px;
}

.left-content .option .indicator {
    display: flex;
}

.left-content .content > p:last-child {
    margin-bottom: 0 !important;
}

.left-content .questionTitle {
    margin-bottom: 15px;
}

.left-content .questionTitle img {
    max-width: 100%;
}

.left-content .option {
    background: #d5d5d5;
    padding: 5px 10px;
    cursor: pointer;
    display: flex;
    gap: 10px;
    align-items: center;
}

.left-content .options {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.left-content .option .content > p {
    margin-bottom: 0px;
    padding-bottom: 0px;
}


.question-actions {
    display: flex;
    justify-content: space-between;
}


.question-boxes {
    padding: 10px 10px;
    display: flex;
    gap: 5px;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
    flex-direction: row;
    align-items: center;
}

.question-boxes .box {
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    background: #FFF;
    overflow: hidden;
    align-items: center;
    cursor: pointer;
}

pre {
    background: #f1f1f1 !important;
    padding: 10px 10px !important;
}


.box.flag {
    border-bottom: 3px solid yellow !important;
}

.box.answered {
    background: #a9ffa9;
}

.box.current {
    border: 1px solid green;
}


.ant-form-item {
    margin-bottom: 10px !important;
}


.note-actions {
    margin-top: 10px;
    display: flex;
    gap: 10px;
}


.explanation img {
    max-width: 100%;
}

.option.valid {
    border: 1px solid green;
    background: #fbf7f7;
}

.box.correct {
    border-top: 3px solid green;
}

.note-area {
    padding: 10px;
    background: #ebebeb;
    margin-top: 20px;
}


.float-area .wrapper {
    display: flex;
    gap: 10px;
    flex-direction: column;
}


.float-area {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;

    margin-top: 20px;
    background: #e7e7e7;
    padding: 5px 0;
    border-top: 2px solid #e1e1e1;
}

.exam-page-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: calc(100vh - 118px);
}

.question-heading {
    background: #000;
    color: #FFF;
    padding: 5px 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}


.question-heading .left div,
.question-heading .right div {
    cursor: pointer;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
}


.wrapper {
    width: 1200px;
    margin: 0 auto;
    max-width: 100% !important;
    padding: 0 20px;
}

.twoRows {
    word-break: break-word;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    line-clamp: 2;
    -webkit-box-orient: vertical;
}

.oneRow {
    word-break: break-word;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1; /* number of lines to show */
    line-clamp: 1;
    -webkit-box-orient: vertical;
}

.threeRows {
    word-break: break-word;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3; /* number of lines to show */
    line-clamp: 3;
    -webkit-box-orient: vertical;
}


.header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 15px 0;
}

.mobile-icon {
    cursor: pointer;
    display: flex;
}

@media screen and (min-width: 1151px) {
    .desktop-menu {
        display: block;
    }
    .mobile-icon {
        display: none;
    }
}

@media screen and (max-width: 1150px) {
    .desktop-menu {
        display: none;
    }

    .mobile-icon {
        display: flex;
    }

    .float-area:not(.show-results) {
        display: none;
    }
    .float-area.show-results {
        padding-top: 5px;
        padding-bottom: 70px;
    }
    .float-wrapper h2 {
        font-size: 20px;
    }

    .question-heading {
        position: fixed;
        width: 100%;
        left: 0;
        bottom: 0;
        padding: 0px;
        z-index: 10000;
    }

    .wrapper {
        padding: 10px;
    }

    .header {
        padding: 0 !important;
    }

    .outer-header {
        margin-bottom: 0 !important;
    }

    .question-wrapper .left-content, .question-wrapper .right-content {
        padding: 10px;
    }

    .btn-back-question {
        padding: 20px;
        padding-right: 70px;
    }

    .btn-next-question {
        padding: 20px;
        padding-left: 70px;
    }
}


.nav-items {
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 20px;
}

.nav-items .nav-item {
    color: #fff;
    font-weight: 500;
    word-spacing: 0px;
}

.outer-header {
    background: #222;
    margin-bottom: 20px;
}

.menu-popup {
    border-top: 1px solid #a3a3a3;
    padding: 20px 0;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.menu-popup .nav-items {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
    flex-wrap: nowrap;
}


.logo a, .logo a:hover, .logo h1 {
    color: #FFF;
    font-size: 20px;
    text-transform: uppercase;
    padding: 0 0;
    margin: 0 0;
    text-decoration: none !important;
}

.explanation {
    word-break: break-word;
}

.wrapper-tree .ant-tree-list-holder {
    padding: 5px 0;
}

.tree-exams .ant-tree-list-holder {
    padding: 5px 0;
}
.tree-exams .ant-tree-switcher-noop {
    width: 10px;
}

iframe {
    height: calc(100vh - 150px);
}


.voucher-note p,
.announcement p {
    margin-bottom: 5px;
}

a {
    color: #19009b;
}

.breakWord {
    word-break: break-word;
}

.collection-area {
    position: fixed;
    top: 265px;
    left: calc(50% - 860px);
}


.note-area {
    position: fixed;
    top: 265px;
    left: calc(50% + 1200px/2);
}

.note-area .textarea {
    height: calc(100% - 70px);
}

.question-navigator {
    gap: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.question-navigator .ant-space-item {
    width: 33%;
}
